import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";
import PageHead from "../../components/shared/PageHead";
import Layout from "../../layouts/en";
import Intro from "../../components/shared/Intro";
import content from "../../data/content/about/about-en";
import * as styles from "../../styles/pages/about/index.module.css";

const AboutPage = (props) => {
  const {
    sAboutUs,
    sAboutUsSec,
    sImgWrap,
    sIntroSection,
    sIntro,
    sIntroDesc,
    cProfesBox,
    cProfesBox2,
    cProfesBox3,
    cSecDesc,
    sParagraph,
  } = styles;
  const { seo, intro, profes, develop, prebelive, belive, features, outro } =
    content;

  return (
    <Layout location={props.location} lang="en">
      <PageHead pageHeadData={seo} />
      <article lang="en" className={sAboutUs} dir="ltr">
        <Intro content={intro} styles={{ sIntroSection, sIntro, sIntroDesc }} />
        <section className={sAboutUsSec}>
          <div className={cProfesBox}>
            <p className={sParagraph}> {profes}</p>
          </div>
          <div className={sImgWrap}>
            <StaticImage
              src="../../assets/images/about/working.png"
              alt="work"
              layout="fullWidth"
            />
          </div>
        </section>
        <section className={sAboutUsSec}>
          <div className={sImgWrap}>
            <StaticImage
              src="../../assets/images/about/team.png"
              alt="Jenko Team"
              layout="fullWidth"
            />
          </div>

          <div className={cProfesBox2}>
            <p className={sParagraph}>{develop}</p>
          </div>
        </section>
        <section className={sAboutUsSec}>
          <div className={cProfesBox3}>
            <p className={sParagraph}>{prebelive}</p>
            <p className={sParagraph}>{belive}</p>
            <ul>
              {features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>
          </div>
          <div className={sImgWrap}>
            <StaticImage
              src="../../assets/images/about/jenko.png"
              alt="Jenko"
              layout="fullWidth"
            />
          </div>
        </section>
        <section>
          <p className={cSecDesc}>{outro}</p>
        </section>
      </article>
    </Layout>
  );
};

export default AboutPage;
