module.exports = {
  seo: {
    title: "Jenko - About Us",
    description: "",
  },
  intro: {
    title: "Who We Are?",
    desc: "Jenko develop & design products in accordance with the ever-changing needs of our diverse\u00A0customers",
  },
  profes:
    "We specialize in industrial design, engineering development, product design and engineering, electronics, electromechanical, research and development, packaging, transfer from development to production, technology-management services\u00A0(CTO).",
  develop:
    "We develop products from the fields of medicine, plastics, electronics, gadgets,\u00A0and more.",
  prebelive:
    "Jenko guides entrepreneurs all the way from the initial idea stages to full production and product\u00A0marketing.",
  belive: "We conduct:",
  features: [
    "Specification & product Characteristics",
    "Engineering Design",
    "Prototype building",
    "Patent search",
    "Patent writing",
    "Investor recruiting",
    "Mass production and marketing",
  ],
  outro:
    "Jenko 3D Engineering is an ultimate solution for any entrepreneur or company looking to develop a product, no matter what stage they are in, what the needs are and what completions need to be made. We are interested in providing a one-stop shop, a comprehensive envelope through which the customer receives an answer\u00A0to\u00A0everything.",
  dir: "ltr",
};
